@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@import url("widget-theme-config.css");

@layer base {
    @font-face {
        font-family: "acorn";
        src: url("../public/assets/fonts/acorn.ttf");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: "jtmw";
        src: url("../public/assets/fonts/jtmw.otf");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
}

.embed-tool__caption {
    @apply hidden;
}

.image-tool__caption {
    @apply hidden;
}

.cdx-block {
    @apply py-0
    m-0
    !important;
}

.codex-editor__loader {
    @apply hidden
  !important;
}

.ce-toolbar__actions {
    @apply sm:right-[111%] 
  !important;
}

.question .ce-block__content {
    @apply bg-white
    max-w-none
    border-none
    border-0
    p-0
    m-0
    text-lg
    font-semibold 
    text-mine-shaft
    tracking-tight
    text-left 
    antialiased 
    !important;
}

.answer .ce-block__content {
    @apply bg-white
    max-w-none
    border-none
    border-0
    p-0
    m-0
    text-lg
    font-medium 
    text-mine-shaft
    tracking-tight
    text-left 
    antialiased 
    !important;
}

.answer-textbox .ce-block__content {
    @apply focus:outline-none
    bg-white
    max-w-none
    border-none
    border-0
    p-0
    m-0
    text-base
    font-normal 
    text-mine-shaft
    tracking-normal
    text-left 
    antialiased 
    !important;
}

.answer-textbox .codex-editor {
    @apply px-3
    py-4
    !important;
}

@layer components {
    .hg-widget-container {
        @apply relative 
  
      ml-widget-ml-mobile
      sm:ml-widget-ml-tablet
      lg:ml-widget-ml-desktop
      mr-widget-mr-mobile
      sm:mr-widget-mr-tablet
      lg:mr-widget-mr-desktop
      mt-widget-mt-mobile
      sm:mt-widget-mt-tablet
      lg:mt-widget-mt-desktop
      mb-widget-mb-mobile
      sm:mb-widget-mb-tablet
      lg:mb-widget-mb-desktop
  
      bg-widget-bg-color
  
      max-w-widget-max-width
  
      px-0
      pt-widget-pt-mobile
      sm:pt-widget-pt-tablet
      lg:pt-widget-pt-desktop
      pb-widget-pb-mobile
      sm:pb-widget-pb-tablet
      lg:pb-widget-pb-desktop
  
      border-none 
      border-0 
      !important;
    }

    .hg-post-container {
        @apply ml-post-container-ml-mobile
      sm:ml-post-container-ml-tablet
      lg:ml-post-container-ml-desktop
      mr-post-container-mr-mobile
      sm:mr-post-container-mr-tablet
      lg:mr-post-container-mr-desktop
      mt-post-container-mt-mobile
      sm:mt-post-container-mt-tablet
      lg:mt-post-container-mt-desktop
      mb-post-container-mb-mobile
      sm:mb-post-container-mb-tablet
      lg:mb-post-container-mb-desktop
  
      pl-widget-pl-mobile
      sm:pl-widget-pl-tablet
      lg:pl-widget-pl-desktop
      pr-widget-pr-mobile
      sm:pr-widget-pr-tablet
      lg:pr-widget-pr-desktop
      pt-widget-pt-mobile
      sm:pt-widget-pt-tablet
      lg:pt-widget-pt-desktop
      pb-widget-pb-mobile
      sm:pb-widget-pb-tablet
      lg:pb-widget-pb-desktop
  
      border-0 
      border-none
      !important;
    }

    .hg-heading {
        @apply font-widget-font-family
      text-heading-font-size-mobile
      sm:text-heading-font-size-tablet
      lg:text-heading-font-size-desktop
      font-heading-font-weight-mobile
      sm:font-heading-font-weight-tablet
      lg:font-heading-font-weight-desktop
      text-heading-font-color-mobile
      sm:text-heading-font-color-tablet
      lg:text-heading-font-color-desktop
      leading-heading-line-height-mobile
      sm:leading-heading-line-height-tablet
      lg:leading-heading-line-height-desktop
      tracking-heading-letter-spacing-mobile
      sm:tracking-heading-letter-spacing-tablet
      lg:tracking-heading-letter-spacing-desktop
  
      text-left 
      not-italic 
      antialiased 
      m-0
      p-0
      border-0 
      border-none
      !important;
    }

    .hg-subheading {
        @apply font-widget-font-family
      text-subheading-font-size-mobile
      sm:text-subheading-font-size-tablet
      lg:text-subheading-font-size-desktop
      font-subheading-font-weight-mobile
      sm:font-subheading-font-weight-tablet
      lg:font-subheading-font-weight-desktop
      text-subheading-font-color-mobile
      sm:text-subheading-font-color-tablet
      lg:text-subheading-font-color-desktop
      leading-subheading-line-height-mobile
      sm:leading-subheading-line-height-tablet
      lg:leading-subheading-line-height-desktop
      tracking-subheading-letter-spacing-mobile
      sm:tracking-subheading-letter-spacing-tablet
      lg:tracking-subheading-letter-spacing-desktop
  
      text-left 
      not-italic 
      antialiased 
      m-0
      p-0
      border-0 
      border-none
      !important;
    }

    .hg-divider {
        @apply border-solid
      border-t-2
      border-b-0
      border-x-0
      mx-4 
      border-white-smoke
      sm:mx-16 
      lg:mx-32  
      my-0
      p-0
      !important;
    }

    .hg-question-list-title-container {
        @apply m-0
      pl-widget-pl-mobile
      sm:pl-widget-pl-tablet
      lg:pl-widget-pl-desktop
      pr-widget-pr-mobile
      sm:pr-widget-pr-tablet
      lg:pr-widget-pr-desktop
  
      pt-question-list-title-container-pt-mobile
      sm:pt-question-list-title-container-pt-tablet
      lg:pt-question-list-title-container-pt-desktop
      pb-question-list-title-container-pb-mobile
      sm:pb-question-list-title-container-pb-tablet
      lg:pb-question-list-title-container-pb-desktop
  
      flex 
      flex-row 
      justify-between 
      items-center
      border-none 
      border-0
      !important;
    }

    .hg-primary-btn {
        @apply font-widget-font-family
      text-primary-btn-font-size-mobile
      sm:text-primary-btn-font-size-tablet
      lg:text-primary-btn-font-size-desktop
      font-primary-btn-font-weight-mobile
      sm:font-primary-btn-font-weight-tablet
      lg:font-primary-btn-font-weight-desktop
      text-primary-btn-font-color-mobile
      sm:text-primary-btn-font-color-tablet
      lg:text-primary-btn-font-color-desktop
      leading-primary-btn-line-height-mobile
      sm:leading-primary-btn-line-height-tablet
      lg:leading-primary-btn-line-height-desktop
      tracking-primary-btn-letter-spacing-mobile
      sm:tracking-primary-btn-letter-spacing-tablet
      lg:tracking-primary-btn-letter-spacing-desktop
      pl-primary-btn-pl-mobile
      sm:pl-primary-btn-pl-tablet
      lg:pl-primary-btn-pl-desktop
      pr-primary-btn-pr-mobile
      sm:pr-primary-btn-pr-tablet
      lg:pr-primary-btn-pr-desktop
      pt-primary-btn-pt-mobile
      sm:pt-primary-btn-pt-tablet
      lg:pt-primary-btn-pt-desktop
      pb-primary-btn-pb-mobile
      sm:pb-primary-btn-pb-tablet
      lg:pb-primary-btn-pb-desktop
  
      bg-primary-btn-bg-color
      hover:bg-primary-btn-hover-bg-color 
      rounded-primary-btn-border-radius-mobile
      sm:rounded-primary-btn-border-radius-tablet
      lg:rounded-primary-btn-border-radius-desktop
  
      m-0
      text-center
      not-italic 
      antialiased 
      cursor-pointer
      focus:outline-none
      outline-none
      flex
      shrink-0
      items-center
      justify-center
      !important;
    }

    .hg-secondary-btn {
        @apply font-widget-font-family
      text-secondary-btn-font-size-mobile
      sm:text-secondary-btn-font-size-tablet
      lg:text-secondary-btn-font-size-desktop
      font-secondary-btn-font-weight-mobile
      sm:font-secondary-btn-font-weight-tablet
      lg:font-secondary-btn-font-weight-desktop
      text-secondary-btn-font-color-mobile
      sm:text-secondary-btn-font-color-tablet
      lg:text-secondary-btn-font-color-desktop
      leading-secondary-btn-line-height-mobile
      sm:leading-secondary-btn-line-height-tablet
      lg:leading-secondary-btn-line-height-desktop
      tracking-secondary-btn-letter-spacing-mobile
      sm:tracking-secondary-btn-letter-spacing-tablet
      lg:tracking-secondary-btn-letter-spacing-desktop
      pl-secondary-btn-pl-mobile
      sm:pl-secondary-btn-pl-tablet
      lg:pl-secondary-btn-pl-desktop
      pr-secondary-btn-pr-mobile
      sm:pr-secondary-btn-pr-tablet
      lg:pr-secondary-btn-pr-desktop
      pt-secondary-btn-pt-mobile
      sm:pt-secondary-btn-pt-tablet
      lg:pt-secondary-btn-pt-desktop
      pb-secondary-btn-pb-mobile
      sm:pb-secondary-btn-pb-tablet
      lg:pb-secondary-btn-pb-desktop
  
      bg-secondary-btn-bg-color
      hover:bg-secondary-btn-hover-bg-color 
      rounded-secondary-btn-border-radius-mobile
      sm:rounded-secondary-btn-border-radius-tablet
      lg:rounded-secondary-btn-border-radius-desktop
  
      m-0
      text-center
      not-italic 
      antialiased 
      cursor-pointer
      focus:outline-none
      outline-none
      flex
      shrink-0
      items-center
      justify-center
      !important;
    }

    .hg-question-card {
        @apply pl-widget-pl-mobile
      sm:pl-widget-pl-tablet
      lg:pl-widget-pl-desktop
      pr-widget-pr-mobile
      sm:pr-widget-pr-tablet
      lg:pr-widget-pr-desktop
  
      pt-question-card-pt-mobile
      sm:pt-question-card-pt-tablet
      lg:pt-question-card-pt-desktop
      pb-question-card-pb-mobile
      sm:pb-question-card-pb-tablet
      lg:pb-question-card-pb-desktop
  
      m-0
      border-none
      border-0
      !important;
    }

    .hg-highlighted-question-card {
        @apply bg-highlighted-question-card-bg-color
  
      pl-widget-pl-mobile
      sm:pl-widget-pl-tablet
      lg:pl-widget-pl-desktop
      pr-widget-pr-mobile
      sm:pr-widget-pr-tablet
      lg:pr-widget-pr-desktop
  
      pt-question-card-pt-mobile
      sm:pt-question-card-pt-tablet
      lg:pt-question-card-pt-desktop
      pb-question-card-pb-mobile
      sm:pb-question-card-pb-tablet
      lg:pb-question-card-pb-desktop
  
      m-0
      border-none
      border-0
      !important;
    }

    .hg-user-question-card {
        @apply bg-user-question-card-bg-color
  
      pl-widget-pl-mobile
      sm:pl-widget-pl-tablet
      lg:pl-widget-pl-desktop
      pr-widget-pr-mobile
      sm:pr-widget-pr-tablet
      lg:pr-widget-pr-desktop
  
      pt-question-card-pt-mobile
      sm:pt-question-card-pt-tablet
      lg:pt-question-card-pt-desktop
      pb-question-card-pb-mobile
      sm:pb-question-card-pb-tablet
      lg:pb-question-card-pb-desktop
  
      m-0
      border-none
      border-0
      !important;
    }

    .hg-private-icon-simple-layout {
        @apply top-private-icon-top-mobile
      sm:top-private-icon-top-tablet
      lg:top-private-icon-top-desktop
      right-private-icon-right-mobile
      sm:right-private-icon-right-tablet
      lg:right-private-icon-right-desktop
      
      w-6 
      h-6
      z-10
      !important;
    }

    .hg-private-icon-accordion-layout {
        @apply right-private-icon-right-mobile
      sm:right-private-icon-right-tablet
      lg:right-private-icon-right-desktop
      
      top-0
      w-6 
      h-6
      z-10
      !important;
    }

    .hg-question {
        @apply pt-question-text-container-pt-mobile
      sm:pt-question-text-container-pt-tablet
      lg:pt-question-text-container-pt-desktop
      pb-question-text-container-pb-mobile
      sm:pb-question-text-container-pb-tablet
      lg:pb-question-text-container-pb-desktop
  
      text-left 
      not-italic 
      antialiased 
      m-0
      border-0 
      border-none
      outline-none
      !important;
    }

    .hg-question-text {
        @apply font-widget-font-family
      text-question-text-font-size-mobile
      sm:text-question-text-font-size-tablet
      lg:text-question-text-font-size-desktop
      font-question-text-font-weight-mobile
      sm:font-question-text-font-weight-tablet
      lg:font-question-text-font-weight-desktop
      text-question-text-font-color-mobile
      sm:text-question-text-font-color-tablet
      lg:text-question-text-font-color-desktop
      leading-question-text-line-height-mobile
      sm:leading-question-text-line-height-tablet
      lg:leading-question-text-line-height-desktop
      tracking-question-text-letter-spacing-mobile
      sm:tracking-question-text-letter-spacing-tablet
      lg:tracking-question-text-letter-spacing-desktop

      max-w-none
      border-none
      border-0
      p-0
      m-0
      text-left 
      not-italic 
      antialiased
      !important;
    }

    .hg-answer {
        @apply text-left 
      not-italic 
      antialiased 
      m-0
      p-0
      border-0 
      border-none
      outline-none
      !important;
    }

    .hg-answer-text {
        @apply font-widget-font-family
      text-answer-text-font-size-mobile
      sm:text-answer-text-font-size-tablet
      lg:text-answer-text-font-size-desktop
      font-answer-text-font-weight-mobile
      sm:font-answer-text-font-weight-tablet
      lg:font-answer-text-font-weight-desktop
      text-answer-text-font-color-mobile
      sm:text-answer-text-font-color-tablet
      lg:text-answer-text-font-color-desktop
      leading-answer-text-line-height-mobile
      sm:leading-answer-text-line-height-tablet
      lg:leading-answer-text-line-height-desktop
      tracking-answer-text-letter-spacing-mobile
      sm:tracking-answer-text-letter-spacing-tablet
      lg:tracking-answer-text-letter-spacing-desktop

      max-w-none
      border-none
      border-0
      p-0
      m-0
      text-left 
      not-italic 
      antialiased 
      !important;
    }

    .hg-profile-img {
        @apply rounded-profile-img-border-radius-mobile
      sm:rounded-profile-img-border-radius-tablet
      lg:rounded-profile-img-border-radius-desktop
      w-profile-img-width-mobile
      sm:w-profile-img-width-tablet
      lg:w-profile-img-width-desktop
      h-profile-img-height-mobile
      sm:h-profile-img-height-tablet
      lg:h-profile-img-height-desktop
  
      object-cover
      outline-none
      border-none
      p-0
      m-0
      !important;
    }

    .hg-qusername {
        @apply font-widget-font-family
      text-qusername-font-size-mobile
      sm:text-qusername-font-size-tablet
      lg:text-qusername-font-size-desktop
      font-qusername-font-weight-mobile
      sm:font-qusername-font-weight-tablet
      lg:font-qusername-font-weight-desktop
      text-qusername-font-color-mobile
      sm:text-qusername-font-color-tablet
      lg:text-qusername-font-color-desktop
      leading-qusername-line-height-mobile
      sm:leading-qusername-line-height-tablet
      lg:leading-qusername-line-height-desktop
      tracking-qusername-letter-spacing-mobile
      sm:tracking-qusername-letter-spacing-tablet
      lg:tracking-qusername-letter-spacing-desktop
  
      text-left 
      not-italic 
      antialiased 
      ml-2
      mr-0
      my-0
      p-0
      border-0 
      border-none
      outline-none
      !important;
    }

    .hg-accordion-dropdown-icon {
        @apply text-accordion-dropdown-icon-font-size-mobile
        sm:text-accordion-dropdown-icon-font-size-tablet
        lg:text-accordion-dropdown-icon-font-size-desktop
        text-accordion-dropdown-icon-font-color-mobile
        sm:text-accordion-dropdown-icon-font-color-tablet
        lg:text-accordion-dropdown-icon-font-color-desktop
        
        transition-all 
        ease-in 
        duration-200
    !important;
    }

    .hg-login-modal-container {
        @apply pl-login-modal-container-pl-mobile
      sm:pl-login-modal-container-pl-tablet
      lg:pl-login-modal-container-pl-desktop
      pr-login-modal-container-pr-mobile
      sm:pr-login-modal-container-pr-tablet
      lg:pr-login-modal-container-pr-desktop
      pt-login-modal-container-pt-mobile
      sm:pt-login-modal-container-pt-tablet
      lg:pt-login-modal-container-pt-desktop
      pb-login-modal-container-pb-mobile
      sm:pb-login-modal-container-pb-tablet
      lg:pb-login-modal-container-pb-desktop
  
      flex 
      justify-center 
      items-start 
      overflow-y-scroll 
      absolute 
      inset-0 
      z-40
      bg-black
      bg-opacity-80 
      m-0
      !important;
    }

    .hg-login-modal {
        @apply pl-login-modal-pl-mobile
      sm:pl-login-modal-pl-tablet
      lg:pl-login-modal-pl-desktop
      pr-login-modal-pr-mobile
      sm:pr-login-modal-pr-tablet
      lg:pr-login-modal-pr-desktop
      pt-login-modal-pt-mobile
      sm:pt-login-modal-pt-tablet
      lg:pt-login-modal-pt-desktop
      pb-login-modal-pb-mobile
      sm:pb-login-modal-pb-tablet
      lg:pb-login-modal-pb-desktop
  
      bg-login-modal-bg-color
  
      rounded-login-modal-border-radius-mobile
      sm:rounded-login-modal-border-radius-tablet
      lg:rounded-login-modal-border-radius-desktop
  
      max-w-login-modal-max-width
  
      relative 
      z-50 
      m-0 
      shadow
      !important;
    }

    .hg-login-description {
        @apply font-widget-font-family
      text-login-description-font-size-mobile
      sm:text-login-description-font-size-tablet
      lg:text-login-description-font-size-desktop
      font-login-description-font-weight-mobile
      sm:font-login-description-font-weight-tablet
      lg:font-login-description-font-weight-desktop
      text-login-description-font-color-mobile
      sm:text-login-description-font-color-tablet
      lg:text-login-description-font-color-desktop
      leading-login-description-line-height-mobile
      sm:leading-login-description-line-height-tablet
      lg:leading-login-description-line-height-desktop
      tracking-login-description-letter-spacing-mobile
      sm:tracking-login-description-letter-spacing-tablet
      lg:tracking-login-description-letter-spacing-desktop
  
      text-left 
      not-italic 
      antialiased 
      py-4 
      px-0 
      m-0 
      border-none 
      outline-none 
      whitespace-pre-line;
    }

    .hg-login-option {
        @apply pt-login-option-pt-mobile
      sm:pt-login-option-pt-tablet
      lg:pt-login-option-pt-desktop
      pb-login-option-pb-mobile
      sm:pb-login-option-pb-tablet
      lg:pb-login-option-pb-desktop
  
      font-widget-font-family
      text-login-option-font-size-mobile
      sm:text-login-option-font-size-tablet
      lg:text-login-option-font-size-desktop
      font-login-option-font-weight-mobile
      sm:font-login-option-font-weight-tablet
      lg:font-login-option-font-weight-desktop
      text-login-option-font-color-mobile
      sm:text-login-option-font-color-tablet
      lg:text-login-option-font-color-desktop
      leading-login-option-line-height-mobile
      sm:leading-login-option-line-height-tablet
      lg:leading-login-option-line-height-desktop
      tracking-login-option-letter-spacing-mobile
      sm:tracking-login-option-letter-spacing-tablet
      lg:tracking-login-option-letter-spacing-desktop
  
      bg-login-option-bg-color
      hover:bg-login-option-hover-bg-color
  
      rounded-login-option-border-radius-mobile
      sm:rounded-login-option-border-radius-tablet
      lg:rounded-login-option-border-radius-desktop
  
      flex
      flex-row
      justify-center
      items-center
      text-center 
      not-italic 
      mx-0
      my-3
      px-0 
      antialiased
      cursor-pointer
      focus:outline-none
      outline-none
      !important;
    }

    .hg-qtextbox-text {
        @apply font-widget-font-family
        text-qtextbox-text-font-size-mobile
        sm:text-qtextbox-text-font-size-tablet
        lg:text-qtextbox-text-font-size-desktop
        font-qtextbox-text-font-weight-mobile
        sm:font-qtextbox-text-font-weight-tablet
        lg:font-qtextbox-text-font-weight-desktop
        text-qtextbox-text-font-color-mobile
        sm:text-qtextbox-text-font-color-tablet
        lg:text-qtextbox-text-font-color-desktop
        leading-qtextbox-text-line-height-mobile
        sm:leading-qtextbox-text-line-height-tablet
        lg:leading-qtextbox-text-line-height-desktop
        tracking-qtextbox-text-letter-spacing-mobile
        sm:tracking-qtextbox-text-letter-spacing-tablet
        lg:tracking-qtextbox-text-letter-spacing-desktop
        text-left 
        not-italic 
        antialiased 
        m-0
        p-0
        border-0 
        border-none
        !important;
    }
}

.hg-primary-btn-border-property {
    border-style: var(--primary-btn-border-style) !important;
    border-width: var(--primary-btn-border-width) !important;
    border-color: var(--primary-btn-border-color) !important;
}

.hg-secondary-btn-border-property {
    border-style: var(--secondary-btn-border-style) !important;
    border-width: var(--secondary-btn-border-width) !important;
    border-color: var(--secondary-btn-border-color) !important;
}

.hg-login-option-border-property {
    border-style: var(--login-option-border-style) !important;
    border-width: var(--login-option-border-width) !important;
    border-color: var(--login-option-border-color) !important;
}

.hg-qtextbox {
    @apply ml-qtextbox-ml-mobile
    sm:ml-qtextbox-ml-tablet
    lg:ml-qtextbox-ml-desktop
    mr-qtextbox-mr-mobile
    sm:mr-qtextbox-mr-tablet
    lg:mr-qtextbox-mr-desktop
    mt-qtextbox-mt-mobile
    sm:mt-qtextbox-mt-tablet
    lg:mt-qtextbox-mt-desktop
    mb-qtextbox-mb-mobile
    sm:mb-qtextbox-mb-tablet
    lg:mb-qtextbox-mb-desktop
  
    pl-qtextbox-pl-mobile
    sm:pl-qtextbox-pl-tablet
    lg:pl-qtextbox-pl-desktop
    pr-qtextbox-pr-mobile
    sm:pr-qtextbox-pr-tablet
    lg:pr-qtextbox-pr-desktop
    pt-qtextbox-pt-mobile
    sm:pt-qtextbox-pt-tablet
    lg:pt-qtextbox-pt-desktop
    pb-qtextbox-pb-mobile
    sm:pb-qtextbox-pb-tablet
    lg:pb-qtextbox-pb-desktop
  
    border-qtextbox-border-width-mobile
    sm:border-qtextbox-border-width-tablet
    lg:border-qtextbox-border-width-desktop
  
    rounded-qtextbox-border-radius-mobile
    sm:rounded-qtextbox-border-radius-tablet
    lg:rounded-qtextbox-border-radius-desktop
  
    border-qtextbox-border-color-mobile
    sm:border-qtextbox-border-color-tablet
    lg:border-qtextbox-border-color-desktop
  
    focus-within:border-qtextbox-border-focus-color-mobile
    sm:focus-within:border-qtextbox-border-focus-color-tablet
    lg:focus-within:border-qtextbox-border-focus-color-desktop
  
    border-solid 
    focus-within:shadow 
    focus:outline-none 
    outline-none;
}

@font-face {
    font-family: "fontello";
    src: url("../public/font/fontello.eot?21079505");
    src: url("../public/font/fontello.eot?21079505#iefix") format("embedded-opentype"),
        url("../public/font/fontello.woff2?21079505") format("woff2"),
        url("../public/font/fontello.woff?21079505") format("woff"),
        url("../public/font/fontello.ttf?21079505") format("truetype"),
        url("../public/font/fontello.svg?21079505#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?21079505#fontello') format('svg');
    }
  }
  */
[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-down-open:before {
    content: "\f004";
} /* 'ï€„' */

:root {
    /* Widget */
    /* Widget margins */
    --widget-ml-mobile: 8px;
    --widget-ml-tablet: 24px;
    --widget-ml-desktop: auto;
    --widget-mr-mobile: 8px;
    --widget-mr-tablet: 24px;
    --widget-mr-desktop: auto;
    --widget-mt-mobile: 16px;
    --widget-mt-tablet: 24px;
    --widget-mt-desktop: 24px;
    --widget-mb-mobile: 16px;
    --widget-mb-tablet: 24px;
    --widget-mb-desktop: 24px;
    /* Widget padding */
    --widget-pl-mobile: 16px;
    --widget-pl-tablet: 64px;
    --widget-pl-desktop: 128px;
    --widget-pr-mobile: 16px;
    --widget-pr-tablet: 64px;
    --widget-pr-desktop: 128px;
    --widget-pt-mobile: 32px;
    --widget-pt-tablet: 32px;
    --widget-pt-desktop: 32px;
    --widget-pb-mobile: 32px;
    --widget-pb-tablet: 32px;
    --widget-pb-desktop: 32px;
    /* Widget background color */
    --widget-bg-color: #ffffff;
    /* Maximum width of widget */
    --widget-max-width: 1024px;
    /* Widget font family */
    --widget-font-family: 'Lato';
  

    /* Container containing the question textbox */
    /* Post container margins */
    --post-container-ml-mobile: 0px;
    --post-container-ml-tablet: 0px;
    --post-container-ml-desktop: 0px;
    --post-container-mr-mobile: 0px;
    --post-container-mr-tablet: 0px;
    --post-container-mr-desktop: 0px;
    --post-container-mt-mobile: 0px;
    --post-container-mt-tablet: 0px;
    --post-container-mt-desktop: 0px;
    --post-container-mb-mobile: 0px;
    --post-container-mb-tablet: 0px;
    --post-container-mb-desktop: 0px;
    /* Post container paddings */
    --post-container-pl-mobile: 16px;
    --post-container-pl-tablet: 64px;
    --post-container-pl-desktop: 128px;
    --post-container-pr-mobile: 16px;
    --post-container-pr-tablet: 64px;
    --post-container-pr-desktop: 128px;
    --post-container-pt-mobile: 32px;
    --post-container-pt-tablet: 32px;
    --post-container-pt-desktop: 32px;
    --post-container-pb-mobile: 32px;
    --post-container-pb-tablet: 32px;
    --post-container-pb-desktop: 32px;
  

    /* Headings like widget title */
    /* Font size */
    --heading-font-size-mobile: 24px;
    --heading-font-size-tablet: 24px;
    --heading-font-size-desktop: 24px;
    /* Font weight */
    --heading-font-weight-mobile: 700;
    --heading-font-weight-tablet: 700;
    --heading-font-weight-desktop: 700;
    /* Font color */
    --heading-font-color-mobile: #232222;
    --heading-font-color-tablet: #232222;
    --heading-font-color-desktop: #232222;
    /* Line height */
    --heading-line-height-mobile: 32px;
    --heading-line-height-tablet: 32px;
    --heading-line-height-desktop: 32px;
    /* Letter spacing */
    --heading-letter-spacing-mobile: 0px;
    --heading-letter-spacing-tablet: 0px;
    --heading-letter-spacing-desktop: 0px;


    /* Subheadings like question list title */
    /* Font size */
    --subheading-font-size-mobile: 20px;
    --subheading-font-size-tablet: 20px;
    --subheading-font-size-desktop: 20px;
    /* Font weight */
    --subheading-font-weight-mobile: 700;
    --subheading-font-weight-tablet: 700;
    --subheading-font-weight-desktop: 700;
    /* Font color */
    --subheading-font-color-mobile: #232222;
    --subheading-font-color-tablet: #232222;
    --subheading-font-color-desktop: #232222;
    /* Line height */
    --subheading-line-height-mobile: 28px;
    --subheading-line-height-tablet: 28px;
    --subheading-line-height-desktop: 28px;
    /* Letter spacing */
    --subheading-letter-spacing-mobile: 0px;
    --subheading-letter-spacing-tablet: 0px;
    --subheading-letter-spacing-desktop: 0px;


    /* Question textbox container */
    /* Margins */
    --qtextbox-ml-mobile: 0px;
    --qtextbox-ml-tablet: 0px;
    --qtextbox-ml-desktop: 0px;
    --qtextbox-mr-mobile: 0px;
    --qtextbox-mr-tablet: 0px;
    --qtextbox-mr-desktop: 0px;
    --qtextbox-mt-mobile: 24px;
    --qtextbox-mt-tablet: 24px;
    --qtextbox-mt-desktop: 24px;
    --qtextbox-mb-mobile: 12px;
    --qtextbox-mb-tablet: 12px;
    --qtextbox-mb-desktop: 12px;
    /* Paddings */
    --qtextbox-pl-mobile: 12px;
    --qtextbox-pl-tablet: 12px;
    --qtextbox-pl-desktop: 12px;
    --qtextbox-pr-mobile: 12px;
    --qtextbox-pr-tablet: 12px;
    --qtextbox-pr-desktop: 12px;
    --qtextbox-pt-mobile: 16px;
    --qtextbox-pt-tablet: 16px;
    --qtextbox-pt-desktop: 16px;
    --qtextbox-pb-mobile: 16px;
    --qtextbox-pb-tablet: 16px;
    --qtextbox-pb-desktop: 16px;
    /* Border radius */
    --qtextbox-border-radius-mobile: 8px;
    --qtextbox-border-radius-tablet: 8px;
    --qtextbox-border-radius-desktop: 8px;
    /* Border width */
    --qtextbox-border-width-mobile: 2px;
    --qtextbox-border-width-tablet: 2px;
    --qtextbox-border-width-desktop: 2px;
    /* Border color */
    --qtextbox-border-color-mobile: #E5E7EB;
    --qtextbox-border-color-tablet: #E5E7EB;
    --qtextbox-border-color-desktop: #E5E7EB;
    /* Focused border color */
    --qtextbox-border-focus-color-mobile: #D8B4FE;
    --qtextbox-border-focus-color-tablet: #D8B4FE;
    --qtextbox-border-focus-color-desktop: #D8B4FE;


    /* Question textbox text */
    /* Font size */
    --qtextbox-text-font-size-mobile: 14px;
    --qtextbox-text-font-size-tablet: 16px;
    --qtextbox-text-font-size-desktop: 16px;
    /* Font weight */
    --qtextbox-text-font-weight-mobile: 400;
    --qtextbox-text-font-weight-tablet: 400;
    --qtextbox-text-font-weight-desktop: 400;
    /* Font color */
    --qtextbox-text-font-color-mobile: #232222;
    --qtextbox-text-font-color-tablet: #232222;
    --qtextbox-text-font-color-desktop: #232222;
    /* Line height */
    --qtextbox-text-line-height-mobile: 20px;
    --qtextbox-text-line-height-tablet: 24px;
    --qtextbox-text-line-height-desktop: 24px;
    /* Letter spacing */
    --qtextbox-text-letter-spacing-mobile: 0px;
    --qtextbox-text-letter-spacing-tablet: 0px;
    --qtextbox-text-letter-spacing-desktop: 0px;


    /* Primary buttons like Post */
    /* Font size */
    --primary-btn-font-size-mobile: 14px;
    --primary-btn-font-size-tablet: 14px;
    --primary-btn-font-size-desktop: 14px;
    /* Font weight */
    --primary-btn-font-weight-mobile: 700;
    --primary-btn-font-weight-tablet: 700;
    --primary-btn-font-weight-desktop: 700;
    /* Font color */
    --primary-btn-font-color-mobile: #ffffff;
    --primary-btn-font-color-tablet: #ffffff;
    --primary-btn-font-color-desktop: #ffffff;
    /* Line height */
    --primary-btn-line-height-mobile: 20px;
    --primary-btn-line-height-tablet: 20px;
    --primary-btn-line-height-desktop: 20px;
    /* Letter spacing */
    --primary-btn-letter-spacing-mobile: 0px;
    --primary-btn-letter-spacing-tablet: 0px;
    --primary-btn-letter-spacing-desktop: 0px;
    /* Background color */
    --primary-btn-bg-color: #232222;
    --primary-btn-hover-bg-color: #000000;
    /* Border radius */
    --primary-btn-border-radius-mobile: 9999px;
    --primary-btn-border-radius-tablet: 9999px;
    --primary-btn-border-radius-desktop: 9999px;
    /* Paddings */
    --primary-btn-pl-mobile: 28px;
    --primary-btn-pl-tablet: 28px;
    --primary-btn-pl-desktop: 28px;
    --primary-btn-pr-mobile: 28px;
    --primary-btn-pr-tablet: 28px;
    --primary-btn-pr-desktop: 28px;
    --primary-btn-pt-mobile: 12px;
    --primary-btn-pt-tablet: 12px;
    --primary-btn-pt-desktop: 12px;
    --primary-btn-pb-mobile: 12px;
    --primary-btn-pb-tablet: 12px;
    --primary-btn-pb-desktop: 12px;
    /* Border style */
    --primary-btn-border-style: none;
    /* Border width */
    --primary-btn-border-width: 1px;
    /* Border color */
    --primary-btn-border-color: #232222;


    /* Secondary buttons like Login */
    /* Font size */
    --secondary-btn-font-size-mobile: 14px;
    --secondary-btn-font-size-tablet: 14px;
    --secondary-btn-font-size-desktop: 14px;
    /* Font weight */
    --secondary-btn-font-weight-mobile: 700;
    --secondary-btn-font-weight-tablet: 700;
    --secondary-btn-font-weight-desktop: 700;
    /* Font color */
    --secondary-btn-font-color-mobile: #232222;
    --secondary-btn-font-color-tablet: #232222;
    --secondary-btn-font-color-desktop: #232222;
    /* Line height */
    --secondary-btn-line-height-mobile: 20px;
    --secondary-btn-line-height-tablet: 20px;
    --secondary-btn-line-height-desktop: 20px;
    /* Letter spacing */
    --secondary-btn-letter-spacing-mobile: 0px;
    --secondary-btn-letter-spacing-tablet: 0px;
    --secondary-btn-letter-spacing-desktop: 0px;
    /* Background color */
    --secondary-btn-bg-color: #ffffff;
    --secondary-btn-hover-bg-color: #ffffff;
    /* Border radius */
    --secondary-btn-border-radius-mobile: 9999px;
    --secondary-btn-border-radius-tablet: 9999px;
    --secondary-btn-border-radius-desktop: 9999px;
    /* Paddings */
    --secondary-btn-pl-mobile: 28px;
    --secondary-btn-pl-tablet: 28px;
    --secondary-btn-pl-desktop: 28px;
    --secondary-btn-pr-mobile: 28px;
    --secondary-btn-pr-tablet: 28px;
    --secondary-btn-pr-desktop: 28px;
    --secondary-btn-pt-mobile: 8px;
    --secondary-btn-pt-tablet: 8px;
    --secondary-btn-pt-desktop: 8px;
    --secondary-btn-pb-mobile: 8px;
    --secondary-btn-pb-tablet: 8px;
    --secondary-btn-pb-desktop: 8px;
    /* Border style */
    --secondary-btn-border-style: solid;
    /* Border width */
    --secondary-btn-border-width: 1px;
    /* Border color */
    --secondary-btn-border-color: #E5E7EB;


    /* Question list title container */
    /* Paddings */
    --question-list-title-container-pt-mobile: 24px;
    --question-list-title-container-pt-tablet: 24px;
    --question-list-title-container-pt-desktop: 24px;
    --question-list-title-container-pb-mobile: 16px;
    --question-list-title-container-pb-tablet: 16px;
    --question-list-title-container-pb-desktop: 16px;


    /* Question card */
    --question-card-pt-mobile: 16px;
    --question-card-pt-tablet: 16px;
    --question-card-pt-desktop: 16px;
    --question-card-pb-mobile: 16px;
    --question-card-pb-tablet: 16px;
    --question-card-pb-desktop: 16px;


    /* User question card */
    --user-question-card-bg-color: #FAFAFA;


    /* Highlighted question card */
    --highlighted-question-card-bg-color: #FFFBEB;


    /* Question text container */
    --question-text-container-pt-mobile: 0px;
    --question-text-container-pt-tablet: 0px;
    --question-text-container-pt-desktop: 0px;
    --question-text-container-pb-mobile: 16px;
    --question-text-container-pb-tablet: 20px;
    --question-text-container-pb-desktop: 20px;


    /* Question text */
    /* Font size */
    --question-text-font-size-mobile: 16px;
    --question-text-font-size-tablet: 18px;
    --question-text-font-size-desktop: 18px;
    /* Font weight */
    --question-text-font-weight-mobile: 700;
    --question-text-font-weight-tablet: 700;
    --question-text-font-weight-desktop: 700;
    /* Font color */
    --question-text-font-color-mobile: #232222;
    --question-text-font-color-tablet: #232222;
    --question-text-font-color-desktop: #232222;
    /* Line height */
    --question-text-line-height-mobile: 24px;
    --question-text-line-height-tablet: 28px;
    --question-text-line-height-desktop: 28px;
    /* Letter spacing */
    --question-text-letter-spacing-mobile: 0px;
    --question-text-letter-spacing-tablet: 0px;
    --question-text-letter-spacing-desktop: 0px;


    /* Answer text */
    /* Font size */
    --answer-text-font-size-mobile: 14px;
    --answer-text-font-size-tablet: 16px;
    --answer-text-font-size-desktop: 16px;
    /* Font weight */
    --answer-text-font-weight-mobile: 400;
    --answer-text-font-weight-tablet: 400;
    --answer-text-font-weight-desktop: 400;
    /* Font color */
    --answer-text-font-color-mobile: #232222;
    --answer-text-font-color-tablet: #232222;
    --answer-text-font-color-desktop: #232222;
    /* Line height */
    --answer-text-line-height-mobile: 20px;
    --answer-text-line-height-tablet: 24px;
    --answer-text-line-height-desktop: 24px;
    /* Letter spacing */
    --answer-text-letter-spacing-mobile: 0px;
    --answer-text-letter-spacing-tablet: 0px;
    --answer-text-letter-spacing-desktop: 0px;

    /* User who asked question */
    /* Font size */
    --qusername-font-size-mobile: 12px;
    --qusername-font-size-tablet: 14px;
    --qusername-font-size-desktop: 14px;
    /* Font weight */
    --qusername-font-weight-mobile: 400;
    --qusername-font-weight-tablet: 400;
    --qusername-font-weight-desktop: 400;
    /* Font color */
    --qusername-font-color-mobile: #4B5563;
    --qusername-font-color-tablet: #4B5563;
    --qusername-font-color-desktop: #4B5563;
    /* Line height */
    --qusername-line-height-mobile: 16px;
    --qusername-line-height-tablet: 20px;
    --qusername-line-height-desktop: 20px;
    /* Letter spacing */
    --qusername-letter-spacing-mobile: 0px;
    --qusername-letter-spacing-tablet: 0px;
    --qusername-letter-spacing-desktop: 0px;


    /* Profile image */
    /* Width */
    --profile-img-width-mobile: 20px;
    --profile-img-width-tablet: 24px;
    --profile-img-width-desktop: 24px;
    /* Height */
    --profile-img-height-mobile: 20px;
    --profile-img-height-tablet: 24px;
    --profile-img-height-desktop: 24px;
    /* Border radius */
    --profile-img-border-radius-mobile: 9999px;
    --profile-img-border-radius-tablet: 9999px;
    --profile-img-border-radius-desktop: 9999px;


    /* Login modal container */
    /* Padding */
    --login-modal-container-pl-mobile: 12px;
    --login-modal-container-pl-tablet: 12px;
    --login-modal-container-pl-desktop: 12px;
    --login-modal-container-pr-mobile: 12px;
    --login-modal-container-pr-tablet: 12px;
    --login-modal-container-pr-desktop: 12px;
    --login-modal-container-pt-mobile: 48px;
    --login-modal-container-pt-tablet: 48px;
    --login-modal-container-pt-desktop: 48px;
    --login-modal-container-pb-mobile: 0px;
    --login-modal-container-pb-tablet: 0px;
    --login-modal-container-pb-desktop: 0px;


    /* Login modal */
    /* Padding */
    --login-modal-pl-mobile: 32px;
    --login-modal-pl-tablet: 56px;
    --login-modal-pl-desktop: 56px;
    --login-modal-pr-mobile: 32px;
    --login-modal-pr-tablet: 56px;
    --login-modal-pr-desktop: 56px;
    --login-modal-pt-mobile: 48px;
    --login-modal-pt-tablet: 56px;
    --login-modal-pt-desktop: 56px;
    --login-modal-pb-mobile: 48px;
    --login-modal-pb-tablet: 56px;
    --login-modal-pb-desktop: 56px;
    /* Max width */
    --login-modal-max-width: 512px;
    /* Border radius */
    --login-modal-border-radius-mobile: 24px;
    --login-modal-border-radius-tablet: 24px;
    --login-modal-border-radius-desktop: 24px;    
    /* Background color */
    --login-modal-bg-color: #ffffff;


    /* Login description */
    /* Font size */
    --login-description-font-size-mobile: 16px;
    --login-description-font-size-tablet: 16px;
    --login-description-font-size-desktop: 16px;
    /* Font weight */
    --login-description-font-weight-mobile: 400;
    --login-description-font-weight-tablet: 400;
    --login-description-font-weight-desktop: 400;
    /* Font color */
    --login-description-font-color-mobile: #232222;
    --login-description-font-color-tablet: #232222;
    --login-description-font-color-desktop: #232222;
    /* Line height */
    --login-description-line-height-mobile: 24px;
    --login-description-line-height-tablet: 24px;
    --login-description-line-height-desktop: 24px;
    /* Letter spacing */
    --login-description-letter-spacing-mobile: 0px;
    --login-description-letter-spacing-tablet: 0px;
    --login-description-letter-spacing-desktop: 0px;


    /* Login option */
    /* Font size */
    --login-option-font-size-mobile: 16px;
    --login-option-font-size-tablet: 16px;
    --login-option-font-size-desktop: 16px;
    /* Font weight */
    --login-option-font-weight-mobile: 700;
    --login-option-font-weight-tablet: 700;
    --login-option-font-weight-desktop: 700;
    /* Font color */
    --login-option-font-color-mobile: #232222;
    --login-option-font-color-tablet: #232222;
    --login-option-font-color-desktop: #232222;
    /* Line height */
    --login-option-line-height-mobile: 24px;
    --login-option-line-height-tablet: 24px;
    --login-option-line-height-desktop: 24px;
    /* Letter spacing */
    --login-option-letter-spacing-mobile: 0px;
    --login-option-letter-spacing-tablet: 0px;
    --login-option-letter-spacing-desktop: 0px;
    /* Paddings */
    --login-option-pt-mobile: 12px;
    --login-option-pt-tablet: 12px;
    --login-option-pt-desktop: 12px;
    --login-option-pb-mobile: 12px;
    --login-option-pb-tablet: 12px;
    --login-option-pb-desktop: 12px;
    /* Background color */
    --login-option-bg-color: #ffffff;
    --login-option-hover-bg-color: #ffffff;
    /* Border style */
    --login-option-border-style: solid;
    /* Border width */
    --login-option-border-width: 1px;
    /* Border color */
    --login-option-border-color: #E5E7EB;
    /* Border radius */
    --login-option-border-radius-mobile: 9999px;
    --login-option-border-radius-tablet: 9999px;
    --login-option-border-radius-desktop: 9999px;
  }
